/* css/PatientDetails.css */
h3 {
  text-align: center;
}
.patient-info {
  display: flex;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.patient-info span {
  white-space: nowrap;
}

.right-content h2 {
  text-align: center;
  margin-bottom: 20px;
}

.glucose-profile-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.glucose-profile-graph, .insulin-bolus-graph {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.glucose-info, .time-in-range {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1;
  align-items: center;
}

.glucose-info p {
  margin: 5px 0;
}

.glucose-profile-graph {
  margin-top: 20px;
}
.daily-glucose-profiles {
  margin: 20px 0;
}

.daily-charts-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.daily-chart {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.daily-chart h4 {
  margin: 0 0 10px;
  font-size: 14px;
  color: #555;
}

.daily-glucose-profiles {
  width: 100%;
  margin-bottom: 2rem;
}

.daily-glucose-profiles h3 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.daily-charts-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr); 
  gap: 1rem; 
  width: 100%; 
  justify-content: center; 
}

.daily-chart {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 0.5rem;
  overflow: hidden;
}

.daily-chart h4 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}


.time-in-range-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.chart-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}

.bar {
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.bar-segment {
  width: 100%;
}

.details-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.range-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-right: 10px;
}

.range-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 0.5;
}
.glucose-info {
  padding: 30px;
}