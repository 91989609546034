/* css/FilterPanel.css */

.filter-panel {
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: #f8f9fa;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .filter-panel__dropdown select {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .filter-panel__checkboxes {
    display: flex;
    gap: 10px;
  }
  
  .filter-panel__checkboxes label {
    font-size: 14px;
  }
  
  .filter-panel__button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .filter-panel__button:hover {
    background-color: #0056b3;
  }
  